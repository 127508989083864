import React from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import PageContainer from "../components/PageContainer"
import Seo from "../components/Seo"
import { useTranslation } from "react-i18next"
import CookieConsent from "react-cookie-consent"

const Contact = ({ data }) => {
  const { page } = data
  const { title, featuredImage, seoImage } = page.frontmatter
  const { description, excerpt } = page
const {t} = useTranslation()
  return (
    <Layout>
       <CookieConsent
        location="bottom"
        buttonText={t("Accept Cookies")}
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        {t("This website uses cookies to enhance the user experience.")}
      </CookieConsent>
      <Seo
        title={t(title)}
        description={t(description) || excerpt}
        image={getSrc(seoImage)}
      />
      <PageContainer title={title} image={featuredImage}>
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: page.html }}
        />
      </PageContainer>
    </Layout>
  )
}

export default Contact

export const pageQuery = graphql`
  query PageQuery($slug: String!, $language: String!) {
    page: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      frontmatter {
        title
        date
        dateFormatted: date(formatString: "MMMM DD, YYYY")
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              width: 1000
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER }
            )
          }
        }
        seoImage: featuredImage {
          childImageSharp {
            gatsbyImageData(layout: FIXED, height: 600, width: 1200)
          }
        }
        description
        tags {
          fields {
            slug
          }
          color
          name
          description
        }
        featured
      }
      excerpt(pruneLength: 150)
    }
    locales: allLocale(filter: {ns: {in: ["common", "index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
